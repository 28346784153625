import React, { useState, useContext, useEffect } from "react"
import { Link, navigate } from "gatsby"
import styled from "styled-components"
import "twin.macro"
import Cookies from "js-cookie"
import Checkbox from "lib/Checkbox"
import {
  Button,
  ButtonGroup,
  Card,
  Responsive,
  Form,
  FormField,
  ValidationInput,
  Icon,
} from "@clevertrack/shared"
import { UserActions, UserTypes } from "app/User/actions"
import { UserContext } from "app/User/context"
import { authenticate, isLoggedIn } from "services/auth"

import { getDefaultValidationMessagesByLang } from "lib/utils/validation"
import emailValidation from "utils/emailValidation"
import cogoToast from "@clevertrackdk/cogo-toast"
import { useFirestoreUser } from "services/firestore/user"
import { IUser } from "app/User/types"
import FEATURE, { PATH_MAP, USERRIGHTS } from "data/featureFlags"
import uniq from "lodash-es/uniq"

const StyledLogin = styled.div`
  position: relative;
  margin-top: 2rem;
  width: 80vw;

  ${(props) => props.theme.media.tablet_portrait_up`
    margin: 2rem auto 0;
    max-width: 60vw;
  `}

  ${(props) => props.theme.media.tablet_portrait_up`
    max-width: 60rem;
  `}

  ${(props) => props.theme.media.tablet_landscape_up`
    max-width: 75%;
  `}

  ${(props) => props.theme.media.desktop_up`
    max-width: 55%;
  `}
`

const CheckboxField = styled.div`
  margin-top: 1rem;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

const StyledSysMsg = styled.small`
  color: ${(props) => props.theme.colors[props.color]};
  font-weight: 400;
  font-size: 75%;
  ${(props) => props.theme.media.tablet_portrait_up`
    float: right;
  `}
`

type LoginResult = {
  color: string
  msg: string
}

function Login({ children, ...props }) {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [remember, setRemember] = useState(false)
  const [sysMsg, setSysMsg] = useState<LoginResult | boolean>(false)
  const { dispatch } = useContext(UserContext)

  const { getFirebaseUser } = useFirestoreUser()

  function isUserLoggedIn() {
    if (!!Cookies.get("clevertrack-authenticated")) {
      isLoggedIn().then((result) => {
        if (result && result.authenticated && typeof window !== `undefined`) {
          navigate("/app")
        }
      })
    }
  }

  function onCheckHandler(value) {
    setRemember(value)
  }

  async function onAuthenticate(e) {
    try {
      e.preventDefault()

      await authenticate(username, password, remember).then((data) => {
        const user: IUser = {
          ...data?.user,
          feature: data?.user?.is_admin
            ? uniq([...data.user.feature, USERRIGHTS.WRITE])
            : data?.user?.feature,
        }
        dispatch(
          UserActions(UserTypes.SetUser, {
            user,
          })
        )

        if (!data.authenticated) {
          cogoToast.error("Bad login")
          setSysMsg({
            msg: "Forkert login, prøv venligst igen",
            color: "red",
          })
        } else {
          if (!!user.is_admin || user.feature.includes(FEATURE.START)) {
            navigate("/app")
          } else {
            const featurePath = user.feature.find((item) => {
              if (PATH_MAP[item]) return true
            })

            if (featurePath) navigate(`/app${PATH_MAP[featurePath]}`)
          }
          setSysMsg({
            msg: "Success",
            color: "success",
          })
        }
      })
    } catch (error) {}
  }

  useEffect(() => {
    isUserLoggedIn()
  }, [])

  const defaultValidationMessages = getDefaultValidationMessagesByLang("da")
  const validationMessages = {
    ...defaultValidationMessages,
    customError: "Ups! Indtast en korrekt email.",
  }

  return (
    <StyledLogin {...props}>
      <h2 tw="text-center">Login</h2>
      {sysMsg && (
        <StyledSysMsg color={sysMsg.color}> {sysMsg.msg} </StyledSysMsg>
      )}
      <Form onSubmit={onAuthenticate}>
        <FormField validationKey="email" label="Email">
          <ValidationInput
            required
            id="email"
            type="email"
            placeholder="Indtast email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            customValidation={emailValidation}
            validationMessages={validationMessages}
          />
        </FormField>
        <FormField validationKey="password" label="Password">
          <ValidationInput
            id="password"
            type="password"
            placeholder="Indtast password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormField>
        <CheckboxField tw="mt-8">
          <Checkbox
            onChange={onCheckHandler}
            appearance="toggle"
            checked={remember}
          >
            <span>Husk mig</span>
          </Checkbox>
        </CheckboxField>
        <Responsive
          phone={
            <ButtonGroup direction="column">
              <Button type="submit" variant="primary">
                Log ind
              </Button>
              <Link
                tw="text-brand-gray-base hover:text-brand-black-base text-xl flex items-center justify-center"
                to="/app/reset-password"
              >
                <span>Har du glemt dit password? Nulstil det her</span>
                <Icon icon="long-arrow-right" tw="w-6 ml-2" />
              </Link>
            </ButtonGroup>
          }
          tabletPortrait={
            <div tw="space-y-4 flex flex-col items-center">
              <Button type="submit" variant="primary" tw="w-1/2">
                Log ind
              </Button>
              <Link
                tw="text-brand-gray-base transition-all hover:text-brand-black-base text-xl"
                to="/app/reset-password"
              >
                Har du glemt dit password? Nulstil det her.
              </Link>
            </div>
          }
        />
      </Form>
    </StyledLogin>
  )
}

export default Login

Login.defaultProps = {}
Login.propTypes = {}
